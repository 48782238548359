exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-articles-2021-04-getting-started-with-people-analytics-tsx": () => import("./../../../src/pages/articles/2021/04/getting-started-with-people-analytics.tsx" /* webpackChunkName: "component---src-pages-articles-2021-04-getting-started-with-people-analytics-tsx" */),
  "component---src-pages-articles-2022-02-accessible-charts-with-excel-and-power-bi-tsx": () => import("./../../../src/pages/articles/2022/02/accessible-charts-with-excel-and-power-bi.tsx" /* webpackChunkName: "component---src-pages-articles-2022-02-accessible-charts-with-excel-and-power-bi-tsx" */),
  "component---src-pages-articles-2022-02-the-business-case-for-people-analytics-tsx": () => import("./../../../src/pages/articles/2022/02/the-business-case-for-people-analytics.tsx" /* webpackChunkName: "component---src-pages-articles-2022-02-the-business-case-for-people-analytics-tsx" */),
  "component---src-pages-articles-2022-03-agile-people-analytics-projects-tsx": () => import("./../../../src/pages/articles/2022/03/agile-people-analytics-projects.tsx" /* webpackChunkName: "component---src-pages-articles-2022-03-agile-people-analytics-projects-tsx" */),
  "component---src-pages-articles-2022-03-data-driven-people-strategy-tsx": () => import("./../../../src/pages/articles/2022/03/data-driven-people-strategy.tsx" /* webpackChunkName: "component---src-pages-articles-2022-03-data-driven-people-strategy-tsx" */),
  "component---src-pages-articles-2022-03-seven-uses-for-people-analytics-tsx": () => import("./../../../src/pages/articles/2022/03/seven-uses-for-people-analytics.tsx" /* webpackChunkName: "component---src-pages-articles-2022-03-seven-uses-for-people-analytics-tsx" */),
  "component---src-pages-articles-2023-03-how-to-improve-employee-wellbeing-with-data-tsx": () => import("./../../../src/pages/articles/2023/03/how-to-improve-employee-wellbeing-with-data.tsx" /* webpackChunkName: "component---src-pages-articles-2023-03-how-to-improve-employee-wellbeing-with-data-tsx" */),
  "component---src-pages-articles-index-tsx": () => import("./../../../src/pages/articles/index.tsx" /* webpackChunkName: "component---src-pages-articles-index-tsx" */),
  "component---src-pages-case-studies-index-tsx": () => import("./../../../src/pages/case-studies/index.tsx" /* webpackChunkName: "component---src-pages-case-studies-index-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-customer-insight-tsx": () => import("./../../../src/pages/customer-insight.tsx" /* webpackChunkName: "component---src-pages-customer-insight-tsx" */),
  "component---src-pages-employee-surveys-index-tsx": () => import("./../../../src/pages/employee-surveys/index.tsx" /* webpackChunkName: "component---src-pages-employee-surveys-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-people-analytics-index-tsx": () => import("./../../../src/pages/people-analytics/index.tsx" /* webpackChunkName: "component---src-pages-people-analytics-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-services-index-tsx": () => import("./../../../src/pages/services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */),
  "component---src-pages-skills-index-tsx": () => import("./../../../src/pages/skills/index.tsx" /* webpackChunkName: "component---src-pages-skills-index-tsx" */),
  "component---src-pages-workplace-ai-index-tsx": () => import("./../../../src/pages/workplace-ai/index.tsx" /* webpackChunkName: "component---src-pages-workplace-ai-index-tsx" */),
  "component---src-templates-common-tsx-content-file-path-src-content-articles-artificial-intelligence-in-hr-mdx": () => import("./../../../src/templates/common.tsx?__contentFilePath=/opt/buildhome/repo/src/content/articles/artificial-intelligence-in-hr.mdx" /* webpackChunkName: "component---src-templates-common-tsx-content-file-path-src-content-articles-artificial-intelligence-in-hr-mdx" */),
  "component---src-templates-common-tsx-content-file-path-src-content-articles-effective-ai-chatbots-mdx": () => import("./../../../src/templates/common.tsx?__contentFilePath=/opt/buildhome/repo/src/content/articles/effective-ai-chatbots.mdx" /* webpackChunkName: "component---src-templates-common-tsx-content-file-path-src-content-articles-effective-ai-chatbots-mdx" */),
  "component---src-templates-common-tsx-content-file-path-src-content-case-studies-insurance-legacy-cloud-migration-mdx": () => import("./../../../src/templates/common.tsx?__contentFilePath=/opt/buildhome/repo/src/content/case-studies/insurance-legacy-cloud-migration.mdx" /* webpackChunkName: "component---src-templates-common-tsx-content-file-path-src-content-case-studies-insurance-legacy-cloud-migration-mdx" */),
  "component---src-templates-common-tsx-content-file-path-src-content-case-studies-performance-analysis-and-reporting-mdx": () => import("./../../../src/templates/common.tsx?__contentFilePath=/opt/buildhome/repo/src/content/case-studies/performance-analysis-and-reporting.mdx" /* webpackChunkName: "component---src-templates-common-tsx-content-file-path-src-content-case-studies-performance-analysis-and-reporting-mdx" */),
  "component---src-templates-common-tsx-content-file-path-src-content-case-studies-professional-services-talent-reporting-mdx": () => import("./../../../src/templates/common.tsx?__contentFilePath=/opt/buildhome/repo/src/content/case-studies/professional-services-talent-reporting.mdx" /* webpackChunkName: "component---src-templates-common-tsx-content-file-path-src-content-case-studies-professional-services-talent-reporting-mdx" */),
  "component---src-templates-common-tsx-content-file-path-src-content-case-studies-redundancy-selection-reports-mdx": () => import("./../../../src/templates/common.tsx?__contentFilePath=/opt/buildhome/repo/src/content/case-studies/redundancy-selection-reports.mdx" /* webpackChunkName: "component---src-templates-common-tsx-content-file-path-src-content-case-studies-redundancy-selection-reports-mdx" */),
  "component---src-templates-common-tsx-content-file-path-src-content-consultancy-azure-development-azure-architecture-mdx": () => import("./../../../src/templates/common.tsx?__contentFilePath=/opt/buildhome/repo/src/content/consultancy/azure-development/azure-architecture.mdx" /* webpackChunkName: "component---src-templates-common-tsx-content-file-path-src-content-consultancy-azure-development-azure-architecture-mdx" */),
  "component---src-templates-common-tsx-content-file-path-src-content-consultancy-azure-development-managing-azure-costs-mdx": () => import("./../../../src/templates/common.tsx?__contentFilePath=/opt/buildhome/repo/src/content/consultancy/azure-development/managing-azure-costs.mdx" /* webpackChunkName: "component---src-templates-common-tsx-content-file-path-src-content-consultancy-azure-development-managing-azure-costs-mdx" */),
  "component---src-templates-common-tsx-content-file-path-src-content-consultancy-azure-development-mdx": () => import("./../../../src/templates/common.tsx?__contentFilePath=/opt/buildhome/repo/src/content/consultancy/azure-development.mdx" /* webpackChunkName: "component---src-templates-common-tsx-content-file-path-src-content-consultancy-azure-development-mdx" */),
  "component---src-templates-common-tsx-content-file-path-src-content-consultancy-azure-development-secure-cloud-applications-mdx": () => import("./../../../src/templates/common.tsx?__contentFilePath=/opt/buildhome/repo/src/content/consultancy/azure-development/secure-cloud-applications.mdx" /* webpackChunkName: "component---src-templates-common-tsx-content-file-path-src-content-consultancy-azure-development-secure-cloud-applications-mdx" */),
  "component---src-templates-common-tsx-content-file-path-src-content-consultancy-hr-data-migration-mdx": () => import("./../../../src/templates/common.tsx?__contentFilePath=/opt/buildhome/repo/src/content/consultancy/hr-data-migration.mdx" /* webpackChunkName: "component---src-templates-common-tsx-content-file-path-src-content-consultancy-hr-data-migration-mdx" */),
  "component---src-templates-common-tsx-content-file-path-src-content-consultancy-oracle-journeys-mdx": () => import("./../../../src/templates/common.tsx?__contentFilePath=/opt/buildhome/repo/src/content/consultancy/oracle-journeys.mdx" /* webpackChunkName: "component---src-templates-common-tsx-content-file-path-src-content-consultancy-oracle-journeys-mdx" */),
  "component---src-templates-common-tsx-content-file-path-src-content-consultancy-oracle-reporting-mdx": () => import("./../../../src/templates/common.tsx?__contentFilePath=/opt/buildhome/repo/src/content/consultancy/oracle-reporting.mdx" /* webpackChunkName: "component---src-templates-common-tsx-content-file-path-src-content-consultancy-oracle-reporting-mdx" */),
  "component---src-templates-common-tsx-content-file-path-src-content-consultancy-power-bi-reporting-mdx": () => import("./../../../src/templates/common.tsx?__contentFilePath=/opt/buildhome/repo/src/content/consultancy/power-bi-reporting.mdx" /* webpackChunkName: "component---src-templates-common-tsx-content-file-path-src-content-consultancy-power-bi-reporting-mdx" */),
  "component---src-templates-common-tsx-content-file-path-src-content-employee-surveys-engagement-surveys-mdx": () => import("./../../../src/templates/common.tsx?__contentFilePath=/opt/buildhome/repo/src/content/employee-surveys/engagement-surveys.mdx" /* webpackChunkName: "component---src-templates-common-tsx-content-file-path-src-content-employee-surveys-engagement-surveys-mdx" */),
  "component---src-templates-common-tsx-content-file-path-src-content-people-analytics-data-engineering-mdx": () => import("./../../../src/templates/common.tsx?__contentFilePath=/opt/buildhome/repo/src/content/people-analytics/data-engineering.mdx" /* webpackChunkName: "component---src-templates-common-tsx-content-file-path-src-content-people-analytics-data-engineering-mdx" */),
  "component---src-templates-common-tsx-content-file-path-src-content-people-analytics-hr-reports-and-dashboards-employee-lifecycle-reporting-mdx": () => import("./../../../src/templates/common.tsx?__contentFilePath=/opt/buildhome/repo/src/content/people-analytics/hr-reports-and-dashboards/employee-lifecycle-reporting.mdx" /* webpackChunkName: "component---src-templates-common-tsx-content-file-path-src-content-people-analytics-hr-reports-and-dashboards-employee-lifecycle-reporting-mdx" */),
  "component---src-templates-common-tsx-content-file-path-src-content-people-analytics-hr-reports-and-dashboards-mdx": () => import("./../../../src/templates/common.tsx?__contentFilePath=/opt/buildhome/repo/src/content/people-analytics/hr-reports-and-dashboards.mdx" /* webpackChunkName: "component---src-templates-common-tsx-content-file-path-src-content-people-analytics-hr-reports-and-dashboards-mdx" */),
  "component---src-templates-common-tsx-content-file-path-src-content-people-analytics-talent-analytics-mdx": () => import("./../../../src/templates/common.tsx?__contentFilePath=/opt/buildhome/repo/src/content/people-analytics/talent-analytics.mdx" /* webpackChunkName: "component---src-templates-common-tsx-content-file-path-src-content-people-analytics-talent-analytics-mdx" */),
  "component---src-templates-common-tsx-content-file-path-src-content-people-analytics-workforce-planning-mdx": () => import("./../../../src/templates/common.tsx?__contentFilePath=/opt/buildhome/repo/src/content/people-analytics/workforce-planning.mdx" /* webpackChunkName: "component---src-templates-common-tsx-content-file-path-src-content-people-analytics-workforce-planning-mdx" */),
  "component---src-templates-common-tsx-content-file-path-src-content-workplace-ai-ai-document-processing-mdx": () => import("./../../../src/templates/common.tsx?__contentFilePath=/opt/buildhome/repo/src/content/workplace-ai/ai-document-processing.mdx" /* webpackChunkName: "component---src-templates-common-tsx-content-file-path-src-content-workplace-ai-ai-document-processing-mdx" */),
  "component---src-templates-common-tsx-content-file-path-src-content-workplace-ai-ai-fraud-detection-mdx": () => import("./../../../src/templates/common.tsx?__contentFilePath=/opt/buildhome/repo/src/content/workplace-ai/ai-fraud-detection.mdx" /* webpackChunkName: "component---src-templates-common-tsx-content-file-path-src-content-workplace-ai-ai-fraud-detection-mdx" */),
  "component---src-templates-common-tsx-content-file-path-src-content-workplace-ai-conversational-ai-mdx": () => import("./../../../src/templates/common.tsx?__contentFilePath=/opt/buildhome/repo/src/content/workplace-ai/conversational-ai.mdx" /* webpackChunkName: "component---src-templates-common-tsx-content-file-path-src-content-workplace-ai-conversational-ai-mdx" */)
}

